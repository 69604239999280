import React, {useRef}  from "react"

import Layout from "../../../../../components/layout"
import SEO from "../../../../../components/seo"



const ElastomericBearingsPtfe = () => {
  //javascript
  const title = 'Elastomeric Bearings with PTFE Sheet';
  const metatitle = 'Elastomeric Bearings with PTFE Sheet';
  const description = 'Elastomeric Bearings with PTFE Sheet : Limit states and threshold values ';
  const metadescription = description;
  const style2 = {
    marginBottom: 80
  }

  const scrollToRef = (ref) => window.scrollTo({
		left:0,
		top:ref.current.offsetTop,
		behavior: 'smooth'
	});
  const Ref1 = useRef(null);
  
  

	const executeScroll = (e, ref) => {
		e.preventDefault();
		scrollToRef(ref);
  }
  return(
    <Layout bodyclass="ls-experimental" title={title}>
      <SEO
        title={metatitle}
        description={metadescription}
      />
      <nav className="anchornav">
        <ul>
          <li>
            <a className="more" href="../elastomeric-bearings">Elastomeric Bearings</a>
          </li>
          <li>
            <a className="more" href="../lead-rubber-bearings">Lead Rubber Bearings (LRB)</a>
          </li>
          <li>
            <a className="more actclass" href="../elastomeric-bearings-ptfe">Elastomeric Bearings with PTFE Sheet</a>
          </li>
          <li>
            <a className="more" href="../steel-bearings">Steel Bearings</a>
          </li>
        </ul>
      </nav>
      <div className="content-table">
      <h2>Table 3: Elastomeric bearings with PTFE sheet: Limit states and threshold values</h2>
      <h3>(R): limit state definition based on soa ,(E): Experimental, (S): Simulation or analysis</h3>
      <table>
        <thead>
          <tr>
            <th colSpan={4}>Engineering Demand Parameter: Shear deformation γ (%)</th>
          </tr>
          <tr>
            <th>Reference</th>
            <th>Limit State</th>
            <th>Shear deformation (γ)</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={3}><button onClick={ e => executeScroll(e, Ref1)}>[1]</button>. (E) LaFave et al. (2013)</td>
            <td>LS2</td>
            <td>125-150%</td>
            <td>Initiation of delamination and damage to PTFE sheet</td>
          </tr>
          <tr>
            <td>LS3</td>
            <td>550-600%</td>
            <td>Severe delamination and damage to PTFE sheet</td>
          </tr>
          <tr>
            <td>LS4</td>
            <td>608%</td>
            <td>Unseating or instability</td>
          </tr>
        </tbody>
        </table>
        </div>
        <div className="references">
          <h4>References</h4>
          <ol>
            <li ref={Ref1}>LaFave, J., Fahnestock, L., Foutch, D., Steelman, J., Revell, J., Filipov, E., Hajjar, J. (2013). Experimental Investigation of the Seismic Response of Bridge Bearings, Research Report No.  FHWA-ICT-13-002,  Illinois Center for Transportation. </li>
          </ol>
        </div>
      
    </Layout>
  )
}

export default ElastomericBearingsPtfe
